import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import queryString from 'querystring';

export const getSearchParam = (param: string): string | undefined => {
  if (typeof window !== 'undefined') {
    const paramValue = new URLSearchParams(window.location.search).get(param) || '';
    return paramValue;
  }
  return undefined;
};

export const useSearchParams = () => {
  const router = useRouter();
  const getSearchParams = () => new URLSearchParams(window.location.search);

  const [searchParams, setInternalSearchParams] = useState<URLSearchParams>(getSearchParams);

  useEffect(() => setInternalSearchParams(getSearchParams), [window.location.search]);

  // If replace === true, then current search params will be deleted
  // and new search params set
  // if replace === false, then new params will override the same
  // params in search string, but other search params won't be deleted
  const setSearchParams = (
    params: Record<string, string | number | boolean | undefined>,
    replace: boolean = false,
  ) => {
    const currentPath = new URL(window.location.href);
    const query = { ...router.query };
    if (replace) {
      currentPath.search = `?${queryString.stringify(params)}`;
    } else {
      const mergedParams = new URLSearchParams(searchParams);
      Object.entries(params).forEach(([paramName, paramValue]) => {
        if (paramValue) {
          query[paramName] = String(paramValue);
          mergedParams.set(paramName, String(paramValue));
        } else {
          delete query[paramName];
          mergedParams.delete(paramName);
        }
      });
      currentPath.search = mergedParams.toString();
    }
    router.push(
      {
        query,
        search: currentPath.search,
      },
      currentPath.pathname + currentPath.search,
    );
  };

  return [searchParams, setSearchParams] as const;
};

export const copyToClipboard = (content: string) => {
  const aux = document.createElement('input');
  aux.setAttribute('value', content);
  document.body.appendChild(aux);
  aux.select();
  document.execCommand('copy');
  document.body.removeChild(aux);
};
