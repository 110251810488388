import { getServer } from '@repugraf/cross-domain-storage';
import React, { useEffect } from 'react';
import { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import 'url-search-params-polyfill';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { ApiContext, CookieContext } from '@utils/contexts';
import { useStore } from '@utils/store';
import { parseCookie } from '@utils/api';
import { appWithTranslation, useTranslation } from 'next-i18next';
import '@utils/themeChanger';
import Api from '../api/Api';

// Styles
import '../styles/global.sass';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { getSearchParam } from '@utils/common';

const lngLocale = {
  zh: 'zh-cn',
  en: 'en',
};

if (typeof window !== 'undefined') {
  const server = getServer({
    allowedDomains: [
      {
        origin: /zionodes.com$/,
        allowedMethods: ['get', 'set', 'remove'],
      },
      {
        origin: /www.zionodes.com$/,
        allowedMethods: ['get', 'set', 'remove'],
      },
      {
        origin: /es.zionodes.com$/,
        allowedMethods: ['get', 'set', 'remove'],
      },
      {
        origin: /www.es.zionodes.com$/,
        allowedMethods: ['get', 'set', 'remove'],
      },
    ],
  });

  server.listen();
}

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
  const { i18n } = useTranslation();
  const store = useStore(pageProps.initialReduxState);
  const router = useRouter();
  const { locale } = router;

  const utmSource = getSearchParam('utm_source');
  const utmMedium = getSearchParam('utm_medium');
  const utmCampaign = getSearchParam('utm_campaign');

  useEffect(() => {
    //@ts-ignore
    if (!window.landingURL) {
      //@ts-ignore
      window.landingURL = window.location.href;
    }

    //@ts-ignore
    if (utmMedium && !window.utmMedium) {
      //@ts-ignore
      window.utmMedium = utmMedium;
    }

    //@ts-ignore
    if (utmCampaign && !window.utmCampaign) {
      //@ts-ignore
      window.utmCampaign = utmCampaign;
    }

    //@ts-ignore
    if (utmSource && !window.utmSource) {
      //@ts-ignore
      window.utmSource = utmSource;
    }
  }, [utmMedium, utmCampaign, utmSource]);

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [i18n, locale]);

  useEffect(() => {
    const handleLanguageChanged = async (lng: string) => {
      if (lng === 'zh') {
        await import('dayjs/locale/zh-cn');
      }

      dayjs.locale(lngLocale[lng as keyof typeof lngLocale]);
    };
    i18n.on('languageChanged', handleLanguageChanged);

    handleLanguageChanged(i18n.language);
    return () => i18n.off('languageChanged', handleLanguageChanged);
  }, [i18n]);

  const api = new Api();

  return (
    <>
      <Provider store={store}>
        <ApiContext.Provider value={api}>
          <CookieContext.Provider value={parseCookie(pageProps.cookie)}>
            <Component {...pageProps} />
          </CookieContext.Provider>
        </ApiContext.Provider>
      </Provider>

      <Script
        strategy="lazyOnload"
        src="https://my.hellobar.com/c0dafb1eb7b39e5d9660089b6d74f400b0fe2ba9.js"
        type="text/javascript"
        charSet="utf-8"
        async={true}
      ></Script>
      <Script
        strategy="lazyOnload"
        id="ze-snippet"
        //@ts-ignore
        rel="dns-prefetch"
        type="text/javascript"
        charSet="utf-8"
        src="https://static.zdassets.com/ekr/snippet.js?key=1b0ef71a-a36d-4002-808f-38e8f8011e99"
        async={true}
      />
      {/* Google Tag Manager */}
      <Script
        strategy="lazyOnload"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
      (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-KMG2RL4')`,
        }}
      />
      {/* End Google Tag Manager */}
    </>
  );
};

// App.getInitialProps = async (appContext) => {
//   const appProps = await App.getInitialProps(appContext);
//   return { ...appProps }
// };

export default appWithTranslation(App);
