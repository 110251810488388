export const IMAGES_URL =
  process.env.APP_ENV === 'production'
    ? 'https://api.marketplace.zionodes.com'
    : 'https://testapi.marketplace.zionodes.com';

export const API_URL =
  process.env.APP_ENV === 'production'
    ? 'https://api.marketplace.zionodes.com/api/v1'
    : 'https://testapi.marketplace.zionodes.com/api/v1';

export const PHONE_REGEXP = '^\\+?[1-9]\\d{1,14}$';

export const WITHDRAWAL_FEE = 10;
export const TRIAL_LIMIT = 1;

export const getStatus = (status: string) => {
  switch (status) {
    case 'NEW':
      return 'New';
    case 'PRE_MINE':
      return 'Pre-mining';
    case 'FOR_SALE':
      return 'Ready for sale';
    case 'ON_SALE':
      return 'On sale';
    default:
      return '';
  }
};
