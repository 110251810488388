import { useMemo } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

let store: any;

const initialState = {
  first_name: '',
  last_name: '',
  image: '',
};

type StateType = {
  first_name: string;
  last_name: string;
  image: string;
};

const reducer = (state = initialState, action: any) => ({
  ...state,
  first_name: action.first_name,
  last_name: action.last_name,
  image: action.image,
});

function initStore(preloadedState = initialState) {
  return createStore(reducer, preloadedState, composeWithDevTools(applyMiddleware()));
}

export const initializeStore = (preloadedState: StateType) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
  let _store = store ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export function useStore(initial: StateType) {
  const storeMemo = useMemo(() => initializeStore(initial), [initial]);
  return storeMemo;
}
