import React from 'react';
import { ApiInterface } from '../api/types';

type CookieType = {
  token?: string;
  expires?: string;
};

export const ApiContext = React.createContext<ApiInterface | null>(null);

export const CookieContext = React.createContext<CookieType | null>(null);

export const useApi = () => {
  const context = React.useContext(ApiContext);
  if (!context) {
    throw new Error('No api route were set');
  }
  return context;
};

export const useCookie = () => React.useContext(CookieContext);
