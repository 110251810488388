import jwt_decode from 'jwt-decode';
import { FORM_ERROR } from 'final-form';
import Cookies from 'js-cookie';

import { TokenType } from '@api/types';

const splitStr = (x: string) => {
  const y = x.split('=');
  return { [y[0].trim()]: y[1].trim() };
};

export type CookieType = {
  token?: string;
  expires?: string;
};

export const parseCookie = (cookie: string): CookieType => {
  const res: CookieType = {};
  if (!cookie) {
    return res;
  }
  const cookies = cookie.split(';');
  cookies.map((rawCookie) => Object.assign(res, splitStr(rawCookie)));
  return res;
};

export const getJWTInfo = () => {
  const cookie = Cookies.get();

  if (cookie.token) {
    return jwt_decode(cookie.token) as TokenType;
  }

  return undefined;
};

type FieldSingleError<TParams> = {
  [Field in keyof TParams]?: TParams[Field] extends object
    ? FieldSingleError<TParams[Field]>
    : string;
};
type ExtractFieldSingleErrorResult<TParams> = FieldSingleError<TParams> & { [FORM_ERROR]?: string };
export function extractFieldSingleError<TValidationErrors>(
  errors: TValidationErrors,
): ExtractFieldSingleErrorResult<TValidationErrors> {
  const fieldError: Record<string, any> = {};

  Object.entries(errors).forEach(([field, error]) => {
    if (error.constructor === Object) {
      fieldError[field] = extractFieldSingleError(error);
    } else if (field === 'non_field_errors') {
      [fieldError[FORM_ERROR]] = error;
    } else {
      [fieldError[field]] = error;
    }
  });

  return fieldError as ExtractFieldSingleErrorResult<TValidationErrors>;
}
