import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  Method,
} from 'axios';
import Router from 'next/router';
import downloadjs from 'downloadjs';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';

import { API_URL } from '@utils/constants';
import { parseCookie } from '@utils/api';
import { i18n } from 'next-i18next';
import {
  ApiInterface,
  LoginApiResponse,
  AsicsApiResponse,
  AsicApiResponse,
  FarmOperatorsResponse,
  FarmOperatorResponse,
  RegisterResponse,
  UserDataResponse,
  MarketplaceItemHardwareResponse,
  FarmOperatorAuditResponse,
  FarmOperatorHardwareResponse,
  RegisterCompanyResponse,
  MyHardwareFilters,
  MyHardwareData,
  ConfirmEmailResponse,
  HashrateResponse,
} from './types';
import { getGAClientId } from '@utils/getGAClientId';
import { UseMarketplaceArg } from './marketplace';
import queryString from 'querystring';

export type RequestType = {
  method: Method;
  url: string;
  requestData?: object;
  redirectOnLogin?: boolean;
  options?: AxiosRequestConfig;
  token?: string;
  withoutToken?: boolean;
};

const languageCode = {
  en: 'en',
  zh: 'zh-cn',
};

class Api implements ApiInterface {
  private readonly baseUrl: string;

  constructor() {
    this.baseUrl = API_URL;
  }

  public async makeRequest<T>({
    method,
    url,
    requestData,
    redirectOnLogin,
    options,
    token,
    withoutToken,
  }: RequestType): Promise<AxiosResponse<T>> {
    const headers = (options?.headers || {}) as AxiosRequestHeaders;
    headers['Accept-Language'] = languageCode[i18n?.language as keyof typeof languageCode] || 'en';
    if (!withoutToken) {
      if (!token) {
        const cookie = parseCookie(document.cookie);
        if (cookie.token) {
          headers.Authorization = `Bearer ${cookie.token}`;
        }
      } else {
        headers.Authorization = `Bearer ${token}`;
      }
    }
    let data;
    try {
      data = await axios.request({
        url: `${this.baseUrl}/${url}`,
        method,
        data: requestData,
        headers,
        ...options,
      });
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.status === 401 && (redirectOnLogin === undefined || redirectOnLogin)) {
        Cookies.remove('token');
        Router.push('/401');
        return error.response as AxiosResponse;
      }
      return error.response as AxiosResponse;
    }
    if (!data) {
      throw new Error(`no data for ${url} with ${requestData}`);
    }
    return data;
  }

  loginUser(data: { username: string; password: string }): Promise<LoginApiResponse> {
    return this.makeRequest({
      method: 'POST',
      url: 'auth/token/',
      requestData: data,
      redirectOnLogin: false,
      withoutToken: true,
    });
  }

  getAllAsics(): Promise<AsicsApiResponse> {
    return this.makeRequest({ method: 'GET', url: 'core/asics/models/' });
  }

  getMarketplaceItem(id: string): Promise<AsicApiResponse> {
    return this.makeRequest({ method: 'GET', url: `core/marketplace/${id}/` });
  }

  getMarketplaceItemHardware(id: string): Promise<MarketplaceItemHardwareResponse> {
    return this.makeRequest({ method: 'GET', url: `core/marketplace/${id}/hardware/` });
  }

  getFarmOperators(page: number): Promise<FarmOperatorsResponse> {
    return this.makeRequest({
      method: 'GET',
      url: `users/farm-operators/?page=${page}&page_size=20`,
      withoutToken: true,
    });
  }

  getFarmOperatorById(id: string): Promise<FarmOperatorResponse> {
    return this.makeRequest({ method: 'GET', url: `users/farm-operators/${id}/` });
  }

  getFarmOperatorAudit(id: string): Promise<FarmOperatorAuditResponse> {
    return this.makeRequest({ method: 'GET', url: `users/farm-operators/${id}/audit/` });
  }

  getFarmOperatorContacts(id: string) {
    return this.makeRequest({ method: 'GET', url: `users/farm-operators/${id}/contacts/` });
  }

  getFarmOperatorAvailableHardware(id: string): Promise<FarmOperatorHardwareResponse> {
    return this.makeRequest({
      method: 'GET',
      url: `users/farm-operators/${id}/available-hardware/`,
    });
  }

  postFarmOperatorReview(data: { id: string; score: number; text: string }): Promise<any> {
    const { id, ...review } = data;
    return this.makeRequest({
      method: 'POST',
      url: `reviews/farm_operator/${id}/`,
      requestData: review,
    });
  }

  getMarketplaceItems(arg: UseMarketplaceArg): Promise<AsicsApiResponse> {
    const params = arg.params ? queryString.stringify(arg.params) : '';

    return this.makeRequest({ method: 'GET', url: `core/marketplace/?${params}` });
  }

  getIncomeByHashrate(th: number): Promise<HashrateResponse> {
    return this.makeRequest({
      method: 'GET',
      url: `core/income-hashrate/${th}`,
      withoutToken: true,
    });
  }

  registerUser(data: {
    email: string;
    password1: string;
    password2: string;
    role: string;
  }): Promise<RegisterResponse> {
    const clientId = getGAClientId();

    const referrer = window.document.referrer.length > 0 ? window.document.referrer : undefined;
    const zionodesLandingSeoInfo: {
      landingURL: string;
      utmMedium: string;
      utmCampaign: string;
      utmSource: string;
      referrer: string;
      firstVisitDate: string;
      clientId: string;
    } = JSON.parse(localStorage.getItem('zionodesLandingSeoInfo') || '{}');

    const firstVisitInfo = {
      landingURL: zionodesLandingSeoInfo.landingURL,
      utmMedium: zionodesLandingSeoInfo.utmMedium,
      utmCampaign: zionodesLandingSeoInfo.utmCampaign,
      utmSource: zionodesLandingSeoInfo.utmSource,
      referrer: zionodesLandingSeoInfo.referrer,
      firstVisitDate: zionodesLandingSeoInfo.firstVisitDate,
      clientId: zionodesLandingSeoInfo.clientId,
    };

    // @ts-ignore
    const {
      utmMedium,
      utmCampaign,
      utmSource,
      landingURL,
    }: { utmMedium: string; utmCampaign: string; utmSource: string; landingURL: string } = window;

    const registrationInfo = {
      clientId,
      utmMedium,
      utmSource,
      utmCampaign,
      referrer,
      landingURL,
    };

    return this.makeRequest({
      method: 'POST',
      url: 'auth/signup/',
      requestData: { ...data, registrationInfo, firstVisitInfo },
      withoutToken: true,
    });
  }

  confirmEmail(id: string, token: string): Promise<ConfirmEmailResponse> {
    return this.makeRequest({
      method: 'GET',
      url: `auth/confirm-mail-change/${id}/${token}/`,
      token,
      redirectOnLogin: false,
      withoutToken: true,
    });
  }

  getUserData(userId: string, token: string): Promise<UserDataResponse> {
    return this.makeRequest({ method: 'GET', url: `users/${userId}/full-profile/`, token });
  }

  registerCompanyReseller(data: {
    email: string;
    password1: string;
    password2: string;
    company_email?: string;
  }): Promise<RegisterCompanyResponse> {
    return this.makeRequest({
      method: 'POST',
      url: 'auth/reseller-company-signup/',
      requestData: data,
    });
  }

  async getMyHardware(filters: MyHardwareFilters): Promise<AxiosResponse<MyHardwareData>> {
    return this.makeRequest<MyHardwareData>({
      method: 'GET',
      url: 'core/asics/my-hardware/',
      requestData: filters,
    });
  }

  async downloadTransactionsHistory(): Promise<void> {
    const response = await this.makeRequest({
      method: 'GET',
      url: 'billing/user-transactions-history/?download=true',
    });
    const dateString = dayjs().format();
    const fileContent = `Transaction ID, Type, Subject, Description, Amount, Balance, Date\n${response.data}`;
    downloadjs(fileContent, `transaction-history-${dateString}.csv`);
  }

  async downloadResellerTransactionsHistory(): Promise<void> {
    const response = await this.makeRequest({
      method: 'GET',
      url: 'billing/reseller-transactions-history/?download=true',
    });
    const dateString = dayjs().format();
    const fileContent = `Transaction ID, Type, Subject, Description, Batch ID, Model ID, Items Amount, Item Price, Date\n${response.data}`;
    downloadjs(fileContent, `transaction-history-${dateString}.csv`);
  }
}

export default Api;
